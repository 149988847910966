<template>
  <div class="workspace-nav">
    <div
      class="py-9 workspace-nav__list">
      <p class="px-6 mb-7 fs-14 text-uppercase grey--text text--darken-3">
        Workspaces
      </p>
      <v-text-field
        v-model="searchWorkspace"
        label="Search Workspaces"
        class="px-6 mt-0 mb-3"
        clearable
        dense
        flat />
      <v-list
        v-if="filteredWorkspaces.length"
        class="py-0 px-3 d-flex flex-column gap-2"
        dark>
        <v-list-item
          v-for="workspace in filteredWorkspaces"
          :key="workspace.id"
          :class="[
            {'v-list-item--active': workspace.id == activeWorkspaceId},
            'px-3 py-3 justify-space-between'
          ]"
          dark>
          <div
            class="d-flex align-center gap-3 pointer"
            @click="() => changeWorkspace(workspace.id)">
            <WorkspaceBubble
              :size="25"
              :class="[`fs-11`, customBgColor(workspace.createdAt)]">
              <span>{{ workspace.name.substring(0, 2) }}</span>
            </WorkspaceBubble>
            <p class="ma-0 fs-16 darkGrey--text">
              {{ workspace.name }}
            </p>
          </div>

          <div class="d-flex align-center gap-3">
            <v-icon
              v-if="workspace.verified && useToggleToMoveToVerifiedArea"
              :size="20"
              color="lightBlue">
              mdi-checkbox-marked-circle
            </v-icon>

            <v-tooltip
              v-if="activeWorkspaceId === workspace.id && canPermissionAdminConsole"
              top>
              <template v-slot:activator="{ on, attrs }">
                <router-link
                  :to="{ name: 'workspace-adminpanel-members', params: { wId: activeWorkspaceId, field: 'members' }}"
                  target="_blank">
                  <v-icon
                    v-bind="attrs"
                    :size="20"
                    color="darkGrey"
                    v-on="on">
                    mdi-briefcase
                  </v-icon>
                </router-link>
                <!-- </router-link> -->
              </template>
              <span>Admin Console</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on">
                  <WorkspaceEditorDialog
                    v-if="workspace.id != PERSONAL_WORKSPACE_ID"
                    :type="TYPE_EDIT"
                    :workspace="workspace" />
                </div>
              </template>
              <span>Edit Workspace</span>
            </v-tooltip>
          </div>
        </v-list-item>

        <v-list-item class="px-3 py-3">
          <WorkspaceEditorDialog
            class="flex-grow-1 pointer" />
        </v-list-item>
      </v-list>
    </div>
    <div
      v-if="$vuetify.breakpoint.mdAndUp"
      class="px-6 py-9 pointer"
      @click="$emit('close-drawer')">
      <v-icon
        color="black"
        size="30">
        mdi-arrow-left-circle
      </v-icon>
      <span class="fs-16 ml-3 vertical-middle darkGrey--text">
        Minimise Menu
      </span>
    </div>
  </div>
</template>
<script>
import {
  mapGetters,
  mapState,
} from 'vuex';
import {
  TYPE_EDIT,
  PERSONAL_WORKSPACE_ID,
} from '@/constants';
// mixins
import AppNavBar from '@/mixins/AppNavBar';
import workspaceListing from '@/mixins/workspaceListing';
// components
import WorkspaceBubble from '@/components/Workspace/WorkspaceBubble';
import WorkspaceEditorDialog from '@/components/Workspace/WorkspaceEditorDialog';
export default {
  name: 'WorkspaceNav',
  components: {
    WorkspaceBubble,
    WorkspaceEditorDialog,
  },
  mixins: [AppNavBar, workspaceListing],
  props: {
    workspaceDrawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TYPE_EDIT,
      PERSONAL_WORKSPACE_ID,
      searchWorkspace: '',
    };
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters('Workspace', ['canPermissionAdminConsole']),
    ...mapGetters('FeatureFlags', ['useToggleToMoveToVerifiedArea']),
    filteredWorkspaces() {
      return this.searchWorkspace
        ? this.alphabeticWorkspaceList.filter(({ name }) => name.toLowerCase().includes(this.searchWorkspace.toLowerCase()))
        : this.alphabeticWorkspaceList.filter((list) => list);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.workspace-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .v-list-item {
    min-height: unset;
    border-radius: 10px;

    &::before, &::after {
      display: none;
    }

    &--active {
      background-color: rgba(53, 49, 53, 0.05);
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    &__list {
      max-height: calc(100% - 115px);
      overflow-y: scroll;
    }
  }
}
</style>